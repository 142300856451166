import React from "react";
import { Link } from "gatsby";
import styles from "./TutorialListNav.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const TutorialListNav = ({ posts }) => {
  return (
    <nav className="pt-5">
      <div className={cx("tutorialTitle")}>
        {posts[0].node.frontmatter.tutorial}
      </div>
      <ul className={cx("posts")}>
        {posts.map(({ node }) => {
          return (
            <li key={node.fields.slug} className={cx("post")}>
              <Link
                to={node.fields.slug}
                className={cx("postLink")}
                activeClassName={cx("active")}
              >
                {node.frontmatter.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default TutorialListNav;
