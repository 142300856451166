import React, { useState } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import PostMain from "../components/TemplatePost/PostMain";
import PostAsideLeft from "../components/TemplatePost/PostAsideLeft";
import PostAsideRight from "../components/TemplatePost/PostAsideRight";
import SidebarToggler from "../components/TemplatePost/SidebarToggler";

export const pageQuery = graphql`
  query PostQuery($slug: String!, $tutorial: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "YYYY년 MM월 DD일")
        author
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
            }
          }
        }
      }
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      timeToRead
      tableOfContents(maxDepth: 3)
    }

    postsInTutorial: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/data/([a-z])+/posts//" }
        frontmatter: { tutorial: { eq: $tutorial } }
      }
      sort: {
        fields: [
          frontmatter___tutorial
          frontmatter___order
          frontmatter___date
        ]
        order: ASC
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tutorial
          }
        }
      }
    }
  }
`;

const PostTemplate = ({ location, data }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { frontmatter, excerpt, tableOfContents, fields } = data.post;

  const _handleClick = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Layout location={location}>
      <SEO title={frontmatter.title} description={excerpt} />

      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <Row>
            <Col xl={2} lg={3} md={3}>
              <PostAsideLeft
                posts={data.postsInTutorial.edges}
                isOpened={isOpened}
              />
            </Col>

            <Col xl={8} lg={9} md={9}>
              <PostMain
                post={data.post}
                siteMetadata={data.site.siteMetadata}
              />
            </Col>

            <Col xl={2} className="d-none d-xl-block">
              <PostAsideRight
                toc={tableOfContents}
                title={frontmatter.title}
                siteMetadata={data.site.siteMetadata}
                slug={fields.slug}
              />
            </Col>
          </Row>
        </Container>

        <SidebarToggler
          handleClick={_handleClick}
          isOpened={isOpened}
          posts={data.postsInTutorial.edges}
        />
      </main>
    </Layout>
  );
};

export default PostTemplate;
