import React from "react";
import TutorialListNav from "./TutorialListNav";
import LeftAsideBox from "./LeftAsideBox";
import styles from "./PostAsideLeft.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const PostAsideLeft = ({ posts, isOpened }) => {
  return (
    <aside className={cx(["asideLeft", { isOpened: isOpened }, "sticky-item"])}>
      <div className={cx("asideInner")}>
        {posts.length > 0 && <TutorialListNav posts={posts} />}
        <LeftAsideBox />
      </div>
      {/* <div className={cx("outStickyBox")}> </div> */}
    </aside>
  );
};

export default PostAsideLeft;
