import React, { useEffect } from "react";
import styles from "./Adsense.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Adsense = () => {
  useEffect(() => {
    if (window && window.innerWidth > 767)
      (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className={cx("Adsense")}>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      <ins
        className={"adsbygoogle"}
        style={{ display: "block" }}
        data-ad-client="ca-pub-7044430195434371"
        data-ad-slot="4284941702"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default Adsense;
