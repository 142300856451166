import React from "react";
import { FaTwitter } from "react-icons/fa";
import styles from "./ShareBtnArea.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const ShareBtnArea = ({ title, siteMetadata, slug }) => {
  const url = siteMetadata.siteUrl + slug;
  const intent = `https://twitter.com/intent/tweet?text=${title}&url=${url}&hashtags=${siteMetadata.title}`;

  return (
    <div className={cx("shareBtnArea")}>
      <div className={cx("shareText")}>SHARE</div>
      <div className={cx("shareButtons")}>
        <div className={cx("shareBtn")}>
          <a
            href={intent}
            className={cx("shareLink")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
            <span className={cx("linkText")}>Tweet</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShareBtnArea;
