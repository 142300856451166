import React from "react";
import { MdMenu, MdClose } from "react-icons/md";
import styles from "./SidebarToggler.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const SidebarToggler = ({ isOpened, handleClick, posts }) => {
  return (
    <div
      role="button"
      className={cx(["openerBtn", posts.length <= 0 && "d-none"])}
      aria-expanded="false"
      onClick={handleClick}
    >
      {isOpened ? (
        <MdClose size={26} color="#f6f6f6" />
      ) : (
        <MdMenu size={26} color="#f6f6f6" />
      )}
    </div>
  );
};

export default SidebarToggler;
