import React from "react";
import classNames from "classnames/bind";
import styles from "./PostMain.module.scss";

import TPArticle from "../Common/TPArticle";
import ShareBtnArea from "./ShareBtnArea";
// import PostPagination from "./PostPagination";

const cx = classNames.bind(styles);

const PostMain = ({ post, siteMetadata }) => {
  return (
    <section className={cx("postSection")}>
      <TPArticle post={post} />
      {/* <PostPagination previous={previous} next={next} /> */}
      <div className="d-xl-none">
        <ShareBtnArea
          title={post.frontmatter.title}
          siteMetadata={siteMetadata}
          slug={post.fields.slug}
        />
      </div>
    </section>
  );
};

export default PostMain;
