import React from "react";
import styles from "./TPArticle.module.scss";

// frontmatter {
//   title
//   date(formatString: "YYYY년 MM월 DD일 HH:mm:SS")
//   author
//   tags
//   image
// }
// excerpt(pruneLength: 160)
// html
// fields {
//   slug
// }
// timeToRead
const TPArticle = ({ post }) => {
  const { frontmatter, html, timeToRead } = post;

  return (
    <article className={styles.content}>
      <h1 className={styles.title}>{frontmatter.title}</h1>

      {frontmatter.date && (
        <div className={styles.metadata}>
          <span>
            <span role="img" aria-label="작성일">
              🗓
            </span>
            {frontmatter.date}
          </span>
          <span className={styles.tpMetaDot}> ∙ </span>
          <span>
            <span role="img" aria-label="작성자">
              🖊
            </span>
            {frontmatter.author || "TutorialPost"}
          </span>
          <span className={styles.tpMetaDot}> ∙ </span>
          <span>
            <span role="img" aria-label="예상시간">
              ⏳
            </span>
            {timeToRead}분
          </span>
        </div>
      )}

      <hr />

      <div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
    </article>
  );
};

export default TPArticle;
