import React from "react";
import styles from "./Toc.module.scss";

const Toc = ({ toc }) => {
  return (
    <div className="pt-5">
      <div className={styles.title}>목차</div>
      <div className={styles.toc} dangerouslySetInnerHTML={{ __html: toc }} />
    </div>
  );
};

export default Toc;
