import React from "react";
import ShareBtnArea from "./ShareBtnArea";
import Toc from "../Common/Toc";

const PostAsideRight = ({ toc, title, siteMetadata, slug }) => {
  return (
    <aside className="sticky-item">
      <ShareBtnArea title={title} siteMetadata={siteMetadata} slug={slug} />
      <Toc toc={toc} />
    </aside>
  );
};

export default PostAsideRight;
