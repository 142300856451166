import React from "react";
import styles from "./LeftAsideBox.module.scss";
import classNames from "classnames/bind";
import Adsense from "../Common/Adsense";

const cx = classNames.bind(styles);

const LeftAsideBox = () => {
  return (
    <div className={cx("LeftAsideBox")}>
      <Adsense />
    </div>
  );
};

export default LeftAsideBox;
